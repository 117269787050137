import React, { useEffect } from "react";
import Redirect from "./Redirect";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Callback from "./Callback";
import WebRedirect from "./WebRedirect";
function App() {
  return (
    <>
    <BrowserRouter>
        <Routes>
        <Route path='/callback' element={< Callback />}></Route>
        <Route exact path='/' element={< Redirect />}></Route>  
        <Route exact path='/webredirect' element={< WebRedirect />}></Route>
        </Routes>
    </BrowserRouter>
    </>
  )
}

export default App;
