import React, { useEffect } from "react";
function WebRedirect() {
  const sendDrawerLoginPageMessageAndCloseWindow = (accessToken, idToken) => {
    window.opener.postMessage(
        JSON.stringify({
          eventName: "SingularityEvent-authTokensReceived",
          metaData: {
            idToken: idToken,
            accessToken: accessToken
          }}
        ),
        '*'
    )
    window.close()
  }

    const sendWalletCreatedForTxnMessageAndCloseWindow = (accessToken, idToken) => {
        window.opener.postMessage(
            JSON.stringify({
                eventName: "SingularityEvent-loginDoneForWalletCreationForTxn",
                metaData: {
                    idToken: idToken,
                    accessToken: accessToken
                }}
            ),
            '*'
        )
        window.close()
    }

  useEffect(() => {
    const currentUrl = window.location.href
    const transformedUrl = currentUrl.split("#")[1]
    const searchParams = new URLSearchParams(transformedUrl)

    const idToken = searchParams.get('id_token')
    const accessToken = searchParams.get('access_token')
    const state = searchParams.get('state')

    if(state && state === 'SINGULARITY_WALLET_CREATION_WHILE_TRANSACTION_LOGIN') {
        sendWalletCreatedForTxnMessageAndCloseWindow(accessToken, idToken)
    } else if(state && state === 'SINGULARITY_WALLET_CREATION_FROM_DRAWER_LOGIN_PAGE') {
        sendDrawerLoginPageMessageAndCloseWindow(accessToken, idToken)
    } else {
        sendDrawerLoginPageMessageAndCloseWindow(accessToken, idToken)
    }
  }, [])
  return <div>Please wait...</div>;
}

export default WebRedirect;
