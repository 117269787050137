import React, { useEffect } from "react";
function Callback() {

  const onCallbackForAndroidReceived = (accessToken, idToken, state) => {
    const applicationId = state["package"]
    if(!applicationId)return
    window.location = `neobrix://${applicationId}?access_token=${accessToken}&id_token=${idToken}`
  }

  useEffect(() => {
    const currentUrl = window.location.href
    const transformedUrl = currentUrl.split("#")[1]
    const searchParams = new URLSearchParams(transformedUrl)
    const idToken = searchParams.get('id_token')

    const accessToken = searchParams.get('access_token')    
    const state = searchParams.get('state')

    if(!accessToken || !idToken || !state)return
    const stateObject = JSON.parse(state)
    const platform = stateObject['platform']

    if(!platform)return

    if(platform == "android"){
      onCallbackForAndroidReceived(accessToken, idToken, stateObject)
    }
  }, [])
  return <div>Please wait...</div>;
}

export default Callback;
