import React, { useEffect } from "react";
import auth0 from 'auth0-js'

function Redirect() {

  const onCallFromAndroidReceived = (applicationId, loginMethod) => {
    const jsonState = JSON.stringify(
        {
          "platform": "android",
          "package": `${applicationId}`
        }
    )

    const webAuth = new auth0.WebAuth({
      domain:       process.env.AUTH0_DOMAIN,
      clientID:     process.env.AUTH0_APP_CLIENT_ID,
      responseType: 'id_token token',
      redirectUri: process.env.AUTH0_REDIRECT_URL,
      state: jsonState
    });

    if(loginMethod === "google"){
      startSocialLogin(webAuth, 'google-oauth2')
    }
    else if(loginMethod === "facebook"){
      startSocialLogin(webAuth, 'facebook')
    }
    else if(loginMethod === "twitter"){
      startSocialLogin(webAuth, 'twitter')
    }
    else if(loginMethod === "discord"){
      startSocialLogin(webAuth, 'discord')
    }
  }

  const startSocialLogin = (webAuth, loginMethod) => {
    webAuth.authorize({
      connection: loginMethod
    });
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    console.log(searchParams)
    const platform = searchParams.get('platform')
    if(!platform)return

    if(platform === "android"){
      const applicationIdEncoded = searchParams.get('appId')
      if(!applicationIdEncoded)return
      const loginMethod = searchParams.get('loginMethod')
      const applicationIdDecoded = applicationIdEncoded.replaceAll("-",".")
      onCallFromAndroidReceived(applicationIdDecoded, loginMethod)
    }
  }, [])

  return <div>Please wait...</div>;
}

export default Redirect;
